import { Injectable, inject } from '@angular/core';
import { type Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { type UploadedFile } from './models/file';
import { type Folder } from './models/folder';
import { type Company } from '../core/models/company';
import { HttpService, type UploadEvent } from '../core/services/http.service';

@Injectable({
  providedIn: 'root',
})
export class FilesService {
  private readonly http = inject(HttpService);
  public uploadingFiles: Array<{ folderId: number; file: UploadedFile }> = [];
  public filesUploadedThisSession = 0;

  public async getCompany(id: string): Promise<Company> {
    return await this.http.get(`api/companies/${id}`);
  }

  public async getCompanyFolders(): Promise<any> {
    return await this.http.get(`/api/companies/my/folders`);
  }

  public async getMyFolders(): Promise<Folder[]> {
    return await this.http.get(`/api/users/my/folders`);
  }

  public async deleteCompanyFolder(id: number): Promise<any> {
    return await this.http.delete(`/api/companies/my/folders/${id}`);
  }

  public async deleteMyFolder(id: number): Promise<any> {
    return await this.http.delete(`/api/users/my/folders/${id}`);
  }

  public async renameCompanyFolder(id: number, data: { name: string }): Promise<any> {
    return await this.http.put(`/api/companies/my/folders/${id}`, data);
  }

  public async renameMyFolder(id: number, data: { name: string }): Promise<any> {
    return await this.http.put(`/api/users/my/folders/${id}`, data);
  }

  public async createCompanyFolder(data: { name: string }): Promise<any> {
    return await this.http.post(`/api/companies/my/folders`, data);
  }

  public async createMyFolder(data: { name: string }): Promise<any> {
    return await this.http.post(`/api/users/my/folders`, data);
  }

  public async deleteCompanyFile(folderId: number, fileId: number): Promise<any> {
    return await this.http.delete(`/api/companies/my/folders/${folderId}/files/${fileId}`);
  }

  public async deleteMyFile(folderId: number, fileId: number): Promise<any> {
    return await this.http.delete(`/api/users/my/folders/${folderId}/files/${fileId}`);
  }

  public async moveCompanyFile(fileId: number, fromFolderId: number, toFolderId: number): Promise<any> {
    return await this.http.post(`/api/companies/my/folders/${fromFolderId}/files/${fileId}/move`, {
      folderId: toFolderId,
    });
  }

  public async moveMyFile(fileId: number, fromFolderId: number, toFolderId: number, companyId?: number): Promise<any> {
    return await this.http.post(`/api/users/my/folders/${fromFolderId}/files/${fileId}/move`, {
      folderId: toFolderId,
      companyId,
    });
  }

  public uploadFile(folderId: number, isCompanyFolder: boolean, file: File): Observable<UploadEvent> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const uploadUrl = `/api/${isCompanyFolder ? 'companies' : 'users'}/my/folders/${folderId}/files`;
    return this.http.upload(uploadUrl, formData);
  }
}
