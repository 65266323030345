import { Component, Input, inject, OnInit } from '@angular/core';

import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { UserService } from '@core/services/user.service';

import { FilesService } from '../files.service';
import { CreateFolderComponent } from '../modals/create-folder/create-folder.component';
import { DeleteFolderComponent } from '../modals/delete-folder/delete-folder.component';
import { RenameFolderComponent } from '../modals/rename-folder/rename-folder.component';
import { type UploadedFile } from '../models/file';
import { type Folder } from '../models/folder';

@Component({
  selector: 'files-page',
  templateUrl: './files-page.component.html',
  styleUrls: ['./files-page.component.scss'],
})
export class FilesPageComponent implements OnInit {
  private readonly userService = inject(UserService);
  private readonly filesService = inject(FilesService);
  private readonly modalService = inject(ModalService);
  @Input() selectMode = false;

  public companyFolders: Folder[] = [];
  public myFolders: Folder[] = [];
  public selectedFolder: Folder;
  public selectedFolderSection: number;
  public files: UploadedFile[] = [];

  public selectedFiles: UploadedFile[] = [];

  public sections = {
    company: 0,
    my: 1,
  };

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public get loadUrl(): string {
    return (
      `/api/${this.selectedFolderSection === this.sections.company ? 'companies' : 'users'}` +
      `/my/folders/${this.selectedFolder.id}/files/search`
    );
  }

  public ngOnInit(): void {
    const promises = [];
    if (this.userService.permissions.User_ViewFiles) {
      promises.push(this.filesService.getMyFolders());
    } else {
      promises.push(Promise.resolve([]));
    }
    if (this.userService.permissions.Company_ViewFiles) {
      promises.push(this.filesService.getCompanyFolders());
    } else {
      promises.push(Promise.resolve([]));
    }
    Promise.all(promises).then((res) => {
      this.myFolders = res[0];
      this.companyFolders = res[1];
      this.selectedFolder = this.companyFolders.length > 0 ? this.companyFolders[0] : this.myFolders[0];
      this.selectedFolderSection = this.companyFolders.length > 0 ? this.sections.company : this.sections.my;
    });
  }

  public selectFolder(folder: Folder, section: number): void {
    if (!this.selectedFolder || this.selectedFolder.id !== folder.id) {
      this.files = [];
      this.selectedFolder = folder;
      this.selectedFolderSection = section;
    }
  }

  public onFileDrop(folder: Folder, file: UploadedFile): void {
    if (folder.id === this.selectedFolder.id) {
      return;
    }

    const isFirstFolderCompany = this.selectedFolderSection === this.sections.company;
    const isSecondFolderCompany = !!this.companyFolders.find((f) => f.id === folder.id);

    if (isFirstFolderCompany && isSecondFolderCompany && !this.permissions.Company_MoveFiles) {
      return;
    }

    if (isFirstFolderCompany && !isSecondFolderCompany) {
      return;
    }

    if (
      (isFirstFolderCompany || isSecondFolderCompany) &&
      isFirstFolderCompany !== isSecondFolderCompany &&
      (!this.permissions.Company_MoveFiles || !this.permissions.User_MoveFiles)
    ) {
      return;
    }

    if (!isFirstFolderCompany && !isSecondFolderCompany && !this.permissions.User_MoveFiles) {
      return;
    }

    if (isFirstFolderCompany) {
      this.filesService.moveCompanyFile(file.id, this.selectedFolder.id, folder.id).then(() => {
        this.files = this.files.filter((f) => f.id !== file.id);
      });
    } else {
      const companyId = isSecondFolderCompany ? this.userService.userProfile.companyId : null;
      this.filesService.moveMyFile(file.id, this.selectedFolder.id, folder.id, companyId).then(() => {
        this.files = this.files.filter((f) => f.id !== file.id);
      });
    }
  }

  public createCompanyFolder(): void {
    this.modalService
      .open({
        content: CreateFolderComponent,
        inputs: {
          defaultName: this.getNewFolderName(this.companyFolders),
          method: async (data) => await this.filesService.createCompanyFolder(data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((folder: Folder) => {
        this.selectedFolder = folder;
        this.loadCompanyFolders();
      })
      .catch(() => {});
  }

  public createMyFolder(): void {
    this.modalService
      .open({
        content: CreateFolderComponent,
        inputs: {
          defaultName: this.getNewFolderName(this.myFolders),
          method: async (data) => await this.filesService.createMyFolder(data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((folder: Folder) => {
        this.selectedFolder = folder;
        this.loadMyFolders();
      })
      .catch(() => {});
  }

  public deleteCompanyFolder(folder: Folder): void {
    this.modalService
      .open({
        content: DeleteFolderComponent,
        inputs: {
          folder,
          method: async (data) => await this.filesService.deleteCompanyFolder(data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectedFolder = this.companyFolders.find((f) => f.id !== folder.id);
        }
        this.loadCompanyFolders();
      })
      .catch(() => {});
  }

  public deleteMyFolder(folder: Folder): void {
    this.modalService
      .open({
        content: DeleteFolderComponent,
        inputs: {
          folder,
          method: async (data) => await this.filesService.deleteMyFolder(data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectedFolder = this.myFolders.find((f) => f.id !== folder.id);
        }
        this.loadMyFolders();
      })
      .catch(() => {});
  }

  public renameCompanyFolder(folder: Folder): void {
    this.modalService
      .open({
        content: RenameFolderComponent,
        inputs: {
          folder,
          method: async (id, data) => await this.filesService.renameCompanyFolder(id, data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((name: string) => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectedFolder.name = name;
        }
        this.loadCompanyFolders();
      })
      .catch(() => {});
  }

  public renameMyFolder(folder: Folder): void {
    this.modalService
      .open({
        content: RenameFolderComponent,
        inputs: {
          folder,
          method: async (id, data) => await this.filesService.renameMyFolder(id, data),
        },
        options: {
          size: 'sm',
        },
      })
      .result.then((name: string) => {
        if (this.selectedFolder && this.selectedFolder.id === folder.id) {
          this.selectedFolder.name = name;
        }
        this.loadMyFolders();
      })
      .catch(() => {});
  }

  private loadCompanyFolders(): void {
    this.filesService.getCompanyFolders().then((folders) => {
      this.companyFolders = folders;
    });
  }

  private loadMyFolders(): void {
    this.filesService.getMyFolders().then((folders) => {
      this.myFolders = folders;
    });
  }

  private getNewFolderName(folders: Folder[]): string {
    const base = 'New Folder';
    let name = base;
    let index = 1;
    while (folders.findIndex((f) => f.name === name) !== -1) {
      name = `${base} ${index}`;
      index++;
    }
    return name;
  }
}
