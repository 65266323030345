import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { ToastrService } from '@core/services/toastr.service';

import { FilesService } from '../../files.service';
import { UploadedFile } from '../../models/file';
import { Folder } from '../../models/folder';

@Component({
  selector: 'app-delete-file',
  templateUrl: './delete-file.component.html',
  styleUrls: ['./delete-file.component.scss'],
  standalone: true,
  imports: [ButtonLoadingDirective, TranslateModule],
})
export class DeleteFileComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly filesService = inject(FilesService);
  private readonly toastr = inject(ToastrService);
  @Input() file: UploadedFile;
  @Input() folder: Folder;
  @Input() isCompanyFolder = false;

  public isLoading = false;

  public deleteFile(): void {
    const promise = this.isCompanyFolder
      ? this.filesService.deleteCompanyFile(this.folder.id, this.file.id)
      : this.filesService.deleteMyFile(this.folder.id, this.file.id);

    this.isLoading = true;
    promise
      .then(() => {
        this.activeModal.close();
      })
      .catch((res) => {
        this.toastr.displayServerErrors(res);
      })
      .finally(() => (this.isLoading = false));
  }
}
