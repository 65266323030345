<div
  (drop)="onDrop($event)"
  [class.dragging-file]="isDraggingFile"
  class="files-table_container"
>
  <input
    #fileInput
    (change)="handleFilesSelect($event.target.files)"
    [accept]="fileTypesString"
    multiple
    style="display: none"
    type="file"
  />

  <app-table
    #table
    [(data)]="data"
    [config]="tableConfig"
    [shrinkHeight]="false"
    [template]="layout === TableLayout.Table ? tableTemplate : gridTemplate"
  >
    <ng-template
      #tableTemplate
      let-file
    >
      <div
        (click)="handleFileClick(file)"
        [appDraggable]="file"
        [attr.appDraggableAnchor]="!file.isUploading && !selectMode ? true : null"
        [class.selected]="isFileSelected(file)"
        [class.uploading]="file.isUploading"
        class="files-table_file-name"
      >
        <div class="files-table_image-container">
          <file-icon
            [file]="file"
            size="sm"
          ></file-icon>
        </div>
        <div>{{ getFullFileName(file) }}</div>
      </div>

      <div
        (click)="handleFileClick(file)"
        [class.selected]="isFileSelected(file)"
        [class.uploading]="file.isUploading"
      >
        {{ getFileSizeString(file) }}
      </div>

      <div
        (click)="handleFileClick(file)"
        [class.selected]="isFileSelected(file)"
        [class.uploading]="file.isUploading"
      >
        {{ file.lastModifiedAt | ccDateFormat: { date: 'long', time: 'default' } }}
      </div>

      @if (!selectMode) {
        <div [class.uploading]="file.isUploading">
          @if (!file.isUploading) {
            <div
              #dropdown="ngbDropdown"
              [appDropdownPosition]="dropdown"
              class="files-table_actions"
              ngbDropdown
            >
              <div
                [appFocusable]
                class="files-table_dropdown-toggle"
                ngbDropdownToggle
              >
                <span></span>
              </div>
              <div
                aria-labelledby="dropdown"
                ngbDropdownMenu
              >
                <button
                  (click)="downloadFile(file)"
                  [disabled]="!canDownloadFiles"
                  [appFocusable]
                  class="dropdown-item"
                >
                  {{ 'filesPage.buttons.download' | translate }}
                </button>
                <button
                  (click)="deleteFile(file)"
                  [disabled]="!canDeleteFiles"
                  [appFocusable]
                  class="dropdown-item"
                >
                  {{ 'common.buttons.delete' | translate }}
                </button>
              </div>
            </div>
          }
        </div>
      }
    </ng-template>

    <ng-template
      #gridTemplate
      let-file
    >
      <div
        [appDraggable]="file"
        [class.uploading]="file.isUploading"
        class="files-table_grid-cell"
      >
        <div
          [attr.appDraggableAnchor]="!file.isUploading && !selectMode ? true : null"
          class="files-table_image-container"
        >
          <file-icon
            [file]="file"
            size="lg"
          ></file-icon>
        </div>
        <div class="files-table_file-name">{{ getFullFileName(file) }}</div>
        <div class="files-table_file-size">
          {{ getFileSizeString(file) }}
        </div>
        @if (!file.isUploading) {
          <div
            class="files-table_actions"
            ngbDropdown
            placement="bottom-right"
          >
            <div
              #dropdownToggle
              class="files-table_dropdown-toggle"
              ngbDropdownToggle
            >
              <span></span>
            </div>
            <div
              aria-labelledby="dropdown"
              ngbDropdownMenu
            >
              <button
                (click)="downloadFile(file)"
                [disabled]="!canDownloadFiles"
                [appFocusable]
                class="dropdown-item"
              >
                {{ 'filesPage.buttons.download' | translate }}
              </button>
              <button
                (click)="deleteFile(file)"
                [disabled]="!canDeleteFiles"
                [appFocusable]
                class="dropdown-item"
              >
                {{ 'common.buttons.delete' | translate }}
              </button>
            </div>
          </div>
        }
      </div>
    </ng-template>
  </app-table>
</div>

<div class="files-table_notification-container">
  @if (isDraggingFile && uploadingFilesInThisFolder.length === 0) {
    <div class="files-table_notification files-table_drop-files">
      <img appAssetSrc="assets/svg/files/drop.svg" />
      <div>{{ 'filesPage.dropFiles' | translate }}</div>
    </div>
  }

  @if (uploadingFilesInThisFolder.length > 0) {
    <div class="files-table_notification files-table_uploading-files">
      <div>
        <img appAssetSrc="assets/svg/files/uploading-file.svg" />
      </div>
      @if (uploadingFilesInThisFolder.length === 1) {
        <div innerHtml="{{ 'filesPage.fileUploading' | translate }}"></div>
      }
      @if (uploadingFilesInThisFolder.length > 1) {
        <div
          innerHtml=" {{ 'filesPage.filesUploading' | translate: { number: uploadingFilesInThisFolder.length } }}"
        ></div>
      }
    </div>
  }

  @if (!isDraggingFile && uploadingFilesInThisFolder.length === 0 && showUploadFinished) {
    <div
      (click)="closeNotification()"
      class="files-table_notification files-table_upload-finished"
    >
      <div>
        <img appAssetSrc="assets/svg/files/upload-complete.svg" />
      </div>
      @if (filesUploadedRecently === 1) {
        <div innerHtml="{{ 'filesPage.fileUploaded' | translate }}"></div>
      }
      @if (filesUploadedRecently > 1) {
        <div innerHtml="{{ 'filesPage.filesUploaded' | translate: { number: filesUploadedRecently } }}"></div>
      }
      <img
        (click)="closeNotification()"
        appAssetSrc="assets/svg/common/cross.svg"
        [appFocusable]
        class="files-table_notification-cross-btn"
      />
    </div>
  }
</div>
