<div class="folders">
  <h1>{{ 'filesPage.title' | translate }}</h1>

  @if (permissions.Company_ViewFiles) {
    <div class="folders-title">
      {{ 'filesPage.companyFiles' | translate }}
    </div>
    <div class="folders-container">
      @for (folder of companyFolders; track folder) {
        <div
          [appFocusable]
          class="folder"
          [appDroppable]="onFileDrop.bind(this, folder)"
          [class.selected]="selectedFolderSection === sections.company && selectedFolder.id === folder.id"
          (click)="selectFolder(folder, sections.company)"
        >
          <img
            [appAssetSrc]="
              selectedFolderSection === sections.company && selectedFolder.id === folder.id
                ? 'assets/svg/files/folder-selected.svg'
                : 'assets/svg/files/folder.svg'
            "
          />
          <div class="folder-name">{{ folder.name }}</div>
          <div
            ngbDropdown
            placement="bottom-right"
            class="dropdown folder-actions"
          >
            <span
              [appFocusable]
              ngbDropdownToggle
              class="dropdown-toggle"
            ></span>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdown"
            >
              <button
                [disabled]="!permissions.Company_RenameFolders"
                [appFocusable]
                class="dropdown-item"
                (click)="renameCompanyFolder(folder)"
              >
                {{ 'common.buttons.rename' | translate }}
              </button>
              <button
                [disabled]="!permissions.Company_DeleteFiles"
                [appFocusable]
                class="dropdown-item"
                (click)="deleteCompanyFolder(folder)"
              >
                {{ 'common.buttons.delete' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
    </div>
    @if (permissions.Company_CreateFolders) {
      <div
        [appFocusable]
        class="folders_add-folder-btn"
        (click)="createCompanyFolder()"
      >
        + {{ 'filesPage.buttons.newFolder' | translate }}
      </div>
    }
  }

  @if (permissions.User_ViewFiles) {
    <div class="folders-title">
      {{ 'filesPage.myFiles' | translate }}
    </div>
    <div class="folders-container">
      @for (folder of myFolders; track folder) {
        <div
          [appFocusable]
          class="folder"
          [appDroppable]="onFileDrop.bind(this, folder)"
          [class.selected]="selectedFolderSection === sections.my && selectedFolder?.id === folder.id"
          (click)="selectFolder(folder, sections.my)"
        >
          <img
            [appAssetSrc]="
              selectedFolderSection === sections.my && selectedFolder?.id === folder.id
                ? 'assets/svg/files/folder-selected.svg'
                : 'assets/svg/files/folder.svg'
            "
          />
          <div class="folder-name">{{ folder.name }}</div>
          <div
            ngbDropdown
            placement="bottom-right"
            class="folder-actions"
          >
            <span
              [appFocusable]
              ngbDropdownToggle
              class="files_dropdown-toggle"
            ></span>
            <div
              ngbDropdownMenu
              aria-labelledby="dropdown"
            >
              <button
                [disabled]="!permissions.User_RenameFolders"
                [appFocusable]
                class="dropdown-item"
                (click)="renameMyFolder(folder)"
              >
                {{ 'common.buttons.rename' | translate }}
              </button>
              <button
                [disabled]="!permissions.User_DeleteFolders"
                [appFocusable]
                class="dropdown-item"
                (click)="deleteMyFolder(folder)"
              >
                {{ 'common.buttons.delete' | translate }}
              </button>
            </div>
          </div>
        </div>
      }
    </div>
    @if (permissions.User_CreateFolders) {
      <div
        [appFocusable]
        class="folders_add-folder-btn"
        (click)="createMyFolder()"
      >
        + {{ 'filesPage.buttons.newFolder' | translate }}
      </div>
    }
  }
</div>

<div class="files_content">
  @if (selectedFolder) {
    <h2>
      <span>{{
        (selectedFolderSection === sections.company ? 'filesPage.companyFiles' : 'filesPage.myFiles') | translate
      }}</span>
      <img appAssetSrc="assets/svg/common/arrow-right-black.svg" />
      <span>{{ selectedFolder.name }}</span>
    </h2>
    <files-table
      [folder]="selectedFolder"
      [(files)]="files"
      [loadUrl]="loadUrl"
      [isCompanyFolder]="this.selectedFolderSection === sections.company"
      [selectMode]="selectMode"
      [(selectedFiles)]="selectedFiles"
      [folderSection]="selectedFolderSection === sections.my ? 'User' : 'Company'"
    ></files-table>
  }
</div>
