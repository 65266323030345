import { Injectable, inject } from '@angular/core';
import { Router, type ActivatedRouteSnapshot, type RouterStateSnapshot, type UrlTree } from '@angular/router';

import { UserService } from '../core/services/user.service';

@Injectable()
export class FilesGuard {
  private readonly userService = inject(UserService);
  private readonly router = inject(Router);

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    if (this.userService.permissions.User_ViewFiles || this.userService.permissions.Company_ViewFiles) {
      return true;
    }

    this.router.navigate(['']);
    return false;
  }
}
