import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { FormErrorMessagesComponent } from '@core/components/form-error-messages/form-error-messages.component';
import { TableComponent } from '@core/components/table/table.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';
import { DraggableDirective } from '@core/directives/draggable.directive';
import { AppDropdownPositionDirective } from '@core/directives/dropdown-position.directive';
import { DroppableDirective } from '@core/directives/droppable.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { DateFormatPipe } from '@core/pipes/date-format.pipe';

import { FileIconComponent } from './components/file-icon/file-icon.component';
import { FilesPageComponent } from './files-page/files-page.component';
import { FilesRoutingModule } from './files-routing.module';
import { FilesTableComponent } from './files-table/files-table.component';
import { FilesGuard } from './files.guard';
import { FilesService } from './files.service';
import { CreateFolderComponent } from './modals/create-folder/create-folder.component';
import { DeleteFileComponent } from './modals/delete-file/delete-file.component';
import { DeleteFolderComponent } from './modals/delete-folder/delete-folder.component';
import { RenameFolderComponent } from './modals/rename-folder/rename-folder.component';

@NgModule({
  declarations: [FilesPageComponent],
  imports: [
    CommonModule,
    FilesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    NgbModule,
    FormErrorMessagesComponent,
    ButtonLoadingDirective,
    AssetSrcDirective,
    FocusableDirective,
    DroppableDirective,
    TableComponent,
    AppDropdownPositionDirective,
    DraggableDirective,
    DateFormatPipe,
    FilesTableComponent,
    CreateFolderComponent,
    DeleteFolderComponent,
    RenameFolderComponent,
    DeleteFileComponent,
    FileIconComponent,
  ],
  providers: [FilesService, FilesGuard],
  bootstrap: [FilesPageComponent],
  exports: [FilesPageComponent],
})
export class FilesModule {}
