import {
  Component,
  ElementRef,
  Input,
  type SimpleChanges,
  ViewChild,
  ViewEncapsulation,
  inject,
  output,
  OnInit,
  OnChanges,
} from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { TableComponent, TableComponent as TableComponent_1 } from '@core/components/table/table.component';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';
import { FocusableDirective } from '@core/directives/focusable.directive';
import { type TableColumnConfig, type TableConfig, TableLayout } from '@core/models/table';
import { ConfigService } from '@core/services/config.service';
import { DragAndDropService } from '@core/services/drag-and-drop.service';
import { ModalService } from '@core/services/modal.service';
import { type UserPermissions } from '@core/services/permission.service';
import { ToastrService } from '@core/services/toastr.service';
import { UserService } from '@core/services/user.service';

import { DraggableDirective } from '../../core/directives/draggable.directive';
import { AppDropdownPositionDirective } from '../../core/directives/dropdown-position.directive';
import { DateFormatPipe } from '../../core/pipes/date-format.pipe';
import { FileIconComponent } from '../components/file-icon/file-icon.component';
import { FilesService } from '../files.service';
import { DeleteFileComponent } from '../modals/delete-file/delete-file.component';
import { type UploadedFile } from '../models/file';
import { Folder } from '../models/folder';

export const MAX_FILE_SIZE = 50 * 1024 * 1024;
export const VALID_FILE_TYPES = [
  '7z',
  'ai',
  'bmp',
  'csv',
  'doc',
  'docx',
  'gif',
  'ico',
  'jpeg',
  'jpg',
  'key',
  'mdb',
  'odp',
  'ods',
  'odt',
  'pdf',
  'png',
  'pps',
  'ppt',
  'pptx',
  'psd',
  'rtf',
  'svg',
  'tif',
  'tiff',
  'txt',
  'xls',
  'xlsx',
  'zip',
];

@Component({
  selector: 'files-table',
  templateUrl: './files-table.component.html',
  styleUrls: ['./files-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TableComponent_1,
    DraggableDirective,
    FileIconComponent,
    NgbDropdown,
    AppDropdownPositionDirective,
    FocusableDirective,
    NgbDropdownToggle,
    NgbDropdownMenu,
    AssetSrcDirective,
    TranslateModule,
    DateFormatPipe,
  ],
})
export class FilesTableComponent implements OnInit, OnChanges {
  private readonly filesService = inject(FilesService);
  private readonly dndService = inject(DragAndDropService);
  private readonly modalService = inject(ModalService);
  private readonly toastr = inject(ToastrService);
  private readonly userService = inject(UserService);
  @Input() folder: Folder;
  @Input() files: UploadedFile[] = [];
  filesChange = output<UploadedFile[]>();
  @Input() loadUrl: string;
  @Input() isCompanyFolder: boolean;
  @Input() selectMode = false;
  @Input() folderSection: 'User' | 'Company';

  @Input() selectedFiles: UploadedFile[] = [];
  selectedFilesChange = output<UploadedFile[]>();

  @ViewChild('table') table: TableComponent;
  @ViewChild('fileInput') fileInput: ElementRef;

  public layout: TableLayout = TableLayout.Grid;
  public tableConfig: TableConfig;
  public showUploadFinished = false;
  public filesUploadedRecently = 0;
  private _allFiles: UploadedFile[] = [];
  private notificationFadeTimeout: any;

  public get permissions(): UserPermissions {
    return this.userService.permissions;
  }

  public get data(): UploadedFile[] {
    return this._allFiles;
  }

  public set data(value: UploadedFile[]) {
    this.filesChange.emit(value);
  }

  public get fileTypesString(): string {
    return '.' + VALID_FILE_TYPES.reduce((p, c) => `${p},.${c}`);
  }

  public get uploadingFilesInThisFolder(): UploadedFile[] {
    return this.filesService.uploadingFiles.filter((uf) => uf.folderId === this.folder.id).map((uf) => uf.file);
  }

  public get TableLayout(): typeof TableLayout {
    return TableLayout;
  }

  public get isDraggingFile(): boolean {
    return this.dndService.isDraggingFile;
  }

  public get canDeleteFiles(): boolean {
    return this.isCompanyFolder ? this.permissions.Company_DeleteFiles : this.permissions.User_DeleteFiles;
  }

  public get canDownloadFiles(): boolean {
    return this.isCompanyFolder ? this.permissions.Company_DownloadFiles : this.permissions.User_DownloadFiles;
  }

  public ngOnInit(): void {
    if (this.selectMode) {
      this.layout = TableLayout.Table;
    }
    this.tableConfig = this.generateTableConfig();
    this._allFiles = [...this.files];
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.files) {
      this._allFiles = [...this.uploadingFilesInThisFolder, ...this.files];
    }
    if (changes.loadUrl) {
      this.tableConfig = { ...this.tableConfig, loadUrl: this.loadUrl };
    }
    if (changes.folder) {
      this.tableConfig = this.generateTableConfig();
    }
  }

  public browseFiles(): void {
    this.fileInput.nativeElement.click();
  }

  public handleFilesSelect(files: FileList): void {
    this.startFileUpload(Array.from(files));
    this.fileInput.nativeElement.value = '';
  }

  public getFullFileName(file: UploadedFile): string {
    return `${file.name}${file.extension ? file.extension : ''}`;
  }

  public getFileSizeString(file: UploadedFile): string {
    let metric: number;
    let metricName: string;
    if (file.size > 1024 * 1024) {
      metric = 1024 * 1024;
      metricName = 'MB';
    } else {
      metric = 1024;
      metricName = 'KB';
    }
    return `${!file.isUploading ? `` : `${(file.loadedSize / metric).toFixed(2)}${metricName} / `}
      ${(file.size / metric).toFixed(2)}${metricName}`;
  }

  public changeLayout(): void {
    this.layout = this.layout === TableLayout.Table ? TableLayout.Grid : TableLayout.Table;
    this.tableConfig = this.generateTableConfig();
  }

  public onDrop(event: DragEvent): void {
    if (this.isCompanyFolder ? !this.permissions.Company_UploadFiles : !this.permissions.User_UploadFiles) {
      return;
    }
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    this.startFileUpload(Array.from(droppedFiles));
  }

  public startFileUpload(files: File[]): void {
    files.forEach((file) => {
      this.uploadFile(file);
    });
    this._allFiles = [...this.uploadingFilesInThisFolder, ...this.files];

    setTimeout(() => {
      this.table.scrollTop();
    }, 0);
  }

  public closeNotification(): void {
    this.showUploadFinished = false;
    this.filesUploadedRecently = 0;
    clearTimeout(this.notificationFadeTimeout);
  }

  public downloadFile(file: UploadedFile): void {
    if (!file.fileDownloadToken) {
      return;
    }

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = `${ConfigService.settings.apiUrl}/api/files/${file.fileDownloadToken}`;
    a.download = file.name + file.extension;
    a.target = '_self';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  public deleteFile(file: UploadedFile): void {
    this.modalService
      .open({
        content: DeleteFileComponent,
        inputs: {
          file,
          folder: this.folder,
          isCompanyFolder: this.isCompanyFolder,
        },
        options: {
          size: 'sm',
        },
      })
      .result.then(() => {
        this.filesChange.emit(this.files.filter((f) => f.id !== file.id));
      })
      .catch(() => {});
  }

  public handleFileClick(file: UploadedFile): void {
    if (!this.selectMode || file.isUploading) {
      return;
    }
    if (!this.isFileSelected(file)) {
      this.selectedFilesChange.emit([
        ...this.selectedFiles,
        {
          ...file,
          fileOwner: this.folderSection,
        },
      ]);
    } else {
      this.selectedFilesChange.emit(this.selectedFiles.filter((f) => f.id !== file.id));
    }
  }

  public isFileSelected(file: UploadedFile): boolean {
    return this.selectedFiles.find((selectedFile) => file.id === selectedFile.id) !== undefined;
  }

  private uploadFile(file: File): void {
    if (file.size > MAX_FILE_SIZE) {
      this.toastr.error('filesPage.errors.fileTooLarge');
      return;
    }
    const fileExt = file.name.slice(file.name.lastIndexOf('.') + 1);
    if (
      VALID_FILE_TYPES.findIndex((type) => type.localeCompare(fileExt, 'en', { sensitivity: 'accent' }) === 0) === -1
    ) {
      this.toastr.error('filesPage.errors.incorrectFileType');
      return;
    }

    // clear previous notifications
    this.closeNotification();
    this.showUploadFinished = false;
    this.filesUploadedRecently = 0;

    const newFile = {
      id: `uploadedFile_${this.filesService.filesUploadedThisSession++}`,
      name: file.name,
      size: file.size,
      loadedSize: 0,
      isUploading: true,
      modifiedAt: new Date().toUTCString(),
    };

    this.filesService.uploadingFiles.push({
      folderId: this.folder.id,
      file: newFile,
    });
    const sub = this.filesService.uploadFile(this.folder.id, this.isCompanyFolder, file).subscribe(
      (res) => {
        switch (res.status) {
          case 'progress':
            newFile.loadedSize = res.loaded;
            break;
          case 'complete':
            sub.unsubscribe();
            newFile.isUploading = false;
            const cache = this.filesService.uploadingFiles.find((uf) => uf.file.id === newFile.id);

            if (cache.folderId === this.folder.id) {
              this.filesUploadedRecently++;
              if (this.uploadingFilesInThisFolder.length === 1) {
                this.finishUpload();
              }
            }

            this.filesService.uploadingFiles = this.filesService.uploadingFiles.filter((f) => f.file.id !== newFile.id);
            this.filesChange.emit([...this.files, res.response]);
            break;
          default:
            break;
        }
      },
      (err) => {
        sub.unsubscribe();
        this.filesService.uploadingFiles = this.filesService.uploadingFiles.filter((f) => f.file.id !== newFile.id);
      },
    );
  }

  private finishUpload(): void {
    this.showUploadFinished = true;
    this.notificationFadeTimeout = setTimeout(() => {
      const notification = document.querySelector('.files-table_upload-finished') as HTMLElement;
      if (!notification) {
        return;
      }
      notification.style.transition = 'opacity 0.75s';
      notification.style.opacity = '0';
      setTimeout(() => {
        this.showUploadFinished = false;
        this.filesUploadedRecently = 0;
      }, 750);
    }, 3000);
  }

  private generateTableConfig(): TableConfig {
    let columns: TableColumnConfig[] = [
      {
        name: 'name',
        label: 'common.labels.name',
        sortable: true,
        minWidth: '200px',
        maxWidth: this.layout === TableLayout.Table ? '6fr' : '10fr',
      },
    ];

    if (this.layout === TableLayout.Table) {
      columns = columns.concat([
        {
          name: 'size',
          label: 'filesPage.tableColumns.fileSize',
          sortable: true,
          minWidth: '120px',
          maxWidth: '2fr',
        },
        {
          name: 'modified',
          label: 'filesPage.tableColumns.modified',
          sortable: true,
          minWidth: '240px',
          maxWidth: '4fr',
        },
      ]);
    }

    if (!this.selectMode) {
      columns.push({
        name: 'connectionType',
        icon: this.layout === TableLayout.Grid ? 'assets/svg/files/grid.svg' : 'assets/svg/files/list.svg',
        onClick: this.changeLayout.bind(this),
        sortable: false,
        minWidth: '40px',
        maxWidth: '40px',
      });
    }

    const buttons = [];

    if (this.isCompanyFolder ? this.permissions.Company_UploadFiles : this.permissions.User_UploadFiles) {
      buttons.push({
        label: 'filesPage.buttons.uploadFiles',
        onClick: this.browseFiles.bind(this),
        class: `btn ${this.selectMode ? 'secondary-btn' : 'btn-primary submit-btn'}`,
      });
    }

    return {
      loadUrl: this.loadUrl,
      loadLimit: this.layout === TableLayout.Table ? 10 : 20,
      filters: [],
      layout: this.layout,
      buttons,
      columns,
    };
  }
}
